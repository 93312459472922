<template>
  <div class="my-page-dashboard my-page" :class="{skeleton:!state.loaded}">
    <div class="row">
      <div class="col-lg-4" :class="r.class" v-for="(r, idx) in state.rects" :key="idx">
        <router-link :to="r.link" class="rect" :style="{borderColor: r.color}">
          <div class="wrapper">
            <div class="subject">
              <span>{{ r.title }}</span>
            </div>
            <span class="value">{{ r.value }}</span>
            <span class="ico" :style="{background: r.color}">
              <i :class="r.ico"></i>
            </span>
          </div>
        </router-link>
      </div>
      <div v-if="!state.isPassedCompatibilityTest" class="col-lg-4">
        <a class="rect half" @click="openCompatibilityTestModal">
          <span>투자자 적합성 테스트</span>
        </a>
      </div>
      <div class="col-lg-4">
        <router-link to="/mypage/inquiry" class="rect half">
          <span>문의하기</span>
        </router-link>
      </div>
      <div class="col-lg-4">
        <a href="https://corp.ohmycompany.com/guide" target="_blank" rel="noopener noreferrer" class="rect half mb-0">
          <span>이용가이드</span>
          <i class="ml-1 fa fa-external-link"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageMyPageDashboard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    menu: Object
  },
  setup(props) {
    const component = new Component(() => {
      state.loaded = false;
      http.get("/api/member/dashboard").then(({data}) => {
        state.loaded = true;
        props.menu.desc = `${store.state.account.memberName}님은 ${data.body.investorGrade}입니다.`;
        state.isPassedCompatibilityTest = data.body.isPassedCompatibilityTest;
        state.rects.find(r => r.name === "investAmount").value = lib.getNumberFormat(data.body.investAmount) + "원";
        state.rects.find(r => r.name === "news").value = lib.getNumberFormat(data.body.newsCount) + "건";
        state.rects.find(r => r.name === "coupon").value = lib.getNumberFormat(data.body.couponCnt) + "개";
        state.rects.find(r => r.name === "point").value = lib.getNumberFormat(data.body.pointAmount) + "P";
        state.rects.find(r => r.name === "openProject").value = lib.getNumberFormat(data.body.openProjectCount) + "건";
        state.rects.find(r => r.name === "message").value = lib.getNumberFormat(data.body.messageCount) + "건";
        state.rects.find(r => r.name === "interest").value = lib.getNumberFormat(data.body.interestCount) + "건";
      });

      if (store.state.account.memberType === "C") {
        const newElement = {
          name: "archiveApplications",
          title: "나의 신청서",
          link: "/archive/applications",
          value: "나의 신청서 목록",
          color: "#808080",
          ico: "fa fa-file-o",
        };
        state.rects.unshift(newElement);
      }
    });

    const state = reactive({
      loaded: false,
      isPassedCompatibilityTest: false,
      rects: [{
        name: "investAmount",
        title: "총 펀딩 금액",
        link: "/mypage/participant/reward",
        value: "0원",
        color: "#825ee4",
        ico: "fa fa-krw",
      }, {
        name: "point",
        title: "보유 포인트",
        link: "/mypage/point",
        value: "0000P",
        color: "#1171ef",
        ico: "fa fa-star",
      }, {
        name: "coupon",
        title: "사용 가능한 쿠폰",
        link: "/mypage/coupon",
        value: "0000개",
        color: "#17bd3a",
        ico: "fa fa-ticket",
      }, {
        name: "news",
        title: "최근 소식",
        link: "/mypage/news",
        value: "0000건",
        color: "#FFB30D",
        ico: "fa fa-newspaper-o",
      }, {
        name: "interest",
        title: "관심 프로젝트",
        link: "/mypage/interest",
        value: "0000건",
        color: "#2dcecc",
        ico: "fa fa-heart",
      }, {
        name: "openProject",
        title: "진행 프로젝트",
        link: "/mypage/open/reward",
        value: "0000건",
        color: "#fb6340",
        ico: "fa fa-cubes",
      }, {
        name: "message",
        title: "최근 메시지",
        link: "/mypage/message/receive",
        value: "0000건",
        color: "#f5365c",
        ico: "fa fa-envelope",
      }, {
        title: "바로가기",
        link: "/mypage/payment",
        value: "후원형 결제 수단",
        color: "#003185",
        ico: "fa fa-credit-card",
      }, {
        title: "바로가기",
        link: "/mypage/investor-account-info",
        value: "증권형 투자 계좌",
        color: "#2d2d3a",
        ico: "fa fa-book",
      },]
    });

    const openCompatibilityTestModal = () => {
      store.commit("openModal", {
        name: "CompatibilityTest",
        callback: "refresh",
        preventClose: true,
        routerReplace: true
      });
    };

    return {component, state, openCompatibilityTestModal};
  }
});
</script>

<style lang="scss" scoped>
.my-page-dashboard {
  > .row {
    > div {
      .rect {
        display: block;
        background: #fff;
        padding: $px35 $px25;
        border: $px1 solid;
        box-shadow: 0 0 $px32 0 rgba(136, 152, 170, 0.15);
        margin-bottom: $px25;
        text-decoration: none;
        height: $px114;
        transition: border-color 0.18s;

        > .wrapper {
          position: relative;

          .subject {
            color: #8898aa;
            font-size: $px13;
            margin-bottom: $px2;
          }

          .value {
            font-weight: 500;
            font-size: $px17;
          }

          .ico {
            position: absolute;
            top: 0;
            right: 0;
            width: $px50;
            height: $px50;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            padding-top: $px13;
          }
        }

        &.half {
          display: block;
          background: #fff;
          margin-bottom: $px14;
          font-size: $px13;
          padding: $px15 $px25;
          height: $px50;
          border-color: $colorAnchor;
        }

        &:not(:hover) {
          border-color: $colorBackground !important;
        }
      }
    }
  }

  &.skeleton {
    > .row > div .rect {
      > .wrapper {
        .subject > span {
          @include skeleton;
        }

        .value, .ico {
          @include skeleton;
        }
      }

      &.half {
        > span, > i {
          @include skeleton;
        }
      }
    }
  }

  @media(max-width: 991px) {
    padding-top: $px5;

    > .row > div .rect {
      margin-bottom: $px15;
    }
  }
}
</style>